<mat-form-field [appearance]="appearance">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <mat-icon *ngIf="this.value" [svgIcon]="this.value?.alpha2Code?.toLowerCase()" class="mr-12 s-20 secondary-text"
              matSuffix></mat-icon>
    <input (blur)="onBlur()" (input)="inputChanged($event?.target?.value)"
           [class]="class"
           [matAutocomplete]="this.countryAutocomplete"
           [placeholder]="this.placeHolder"
           [readonly]="this.readonly"
           [tabIndex]="tabIndex"
           [value]="showCallingCode ? (this.value ? (this.value?.name + ' (' + this.value?.callingCode + ')') : '') : this.value?.name"
           [required]="this.required"
           [disabled]="this.disabled || this.loadingDB"
           [autocomplete]="this.autocomplete"
           [name]="this.name"
           aria-label="country"
           matInput type="text"
           #inputElement>
    <mat-progress-bar *ngIf="this.loadingDB || this.loading" mode="buffer"></mat-progress-bar>
    <mat-autocomplete #countryAutocomplete="matAutocomplete" (opened)="autocompleteScroll()"
                      (optionSelected)="onOptionsSelected($event)">
        <mat-option *ngFor="let country of filteredOptions" [value]="country?.name">
            <mat-icon [svgIcon]="country?.alpha2Code?.toLowerCase()"></mat-icon>
            <small *ngIf="!showCallingCode">{{ [country?.name, country?.alpha3Code] | joinStrings }}</small>
            <small *ngIf="showCallingCode">{{ [country?.name, '(' + country?.callingCode + ')'] | joinStrings:' ' }}</small>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
